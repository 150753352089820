import React, { useContext } from 'react';
import { Pager } from '../Pager/Pager.component';
import { QAResultContent } from '../QAResultContent/QAResultContent';
import { useSEO } from './useSEO';
import QAContext from '../../context/QAContext';
import { useQAResult } from './useQAResult';
import { dataModel } from '../dataModel';
import './QAResult.scss';
import { QAResultHeader } from './QAResultHeader';

const QUESTION_DISPLAY_MOBILE = 8;

export const QAResultMobile = () => {
  let{
    TotalResults, Results, seoPageNumber, searchText
  } = useContext(QAContext);

  const qaResult = useQAResult();

  const {
    seoResults, seoAnswers, seoTotalResults, seoPrevPage, seoNextPage
  } = useSEO(qaResult);
  if (seoPageNumber && seoResults) {
    Results = seoResults;
  }

  const showResults = !!(Results?.length || seoResults?.length || !searchText);

  return (
    <>
      <QAResultHeader seoTotalResults={seoTotalResults} seoResults={seoResults} />
      {showResults && (
        <div>
          <QAResultContent
            seoResults={seoResults}
            seoAnswers={seoAnswers}
            seoTotalResults={seoTotalResults}
          />
          {Results?.length && !qaResult && (
            <div className="sui-w-full">
              {TotalResults > QUESTION_DISPLAY_MOBILE && (
                <div className="sui-mb-4">
                  <Pager />
                </div>
              )}
            </div>
          )}
          {qaResult && (
            <div>
              <div>
                {seoPrevPage > -1 && (<a href={`${seoPrevPage}`}>{`page ${seoPrevPage}`}</a>)}{' '}
                {seoNextPage > -1 && (<a href={`${seoNextPage}`}>{`page ${seoNextPage}`}</a>)}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

QAResultMobile.displayName = 'QAResultMobile';

QAResultMobile.dataModel = dataModel;
