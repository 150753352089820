import React, { useContext, useMemo, useState } from 'react';
import { arrayOf, shape } from 'prop-types';
import { Card, CardTitle, Typography, CardContent } from '@one-thd/sui-atomic-components';
import { Overlay } from '@thd-olt-component-react/core-ui';
import { formatDate } from '../../../helpers/QAHelper';
import { Voting } from '../../Voting/Voting.component';
import { UserBadges } from './UserBadges';
import QAContext from '../../../context/QAContext';
import { getHighlightedText } from '../helpers/getHighlightedText';
import { UserProfile } from '../../UserProfile/UserProfile';
import { MediaCarousel } from './MediaCarousel';

export const AnswerContainer = ({ result, seoData }) => {
  const {
    UserNickname: resultNickName,
    SubmissionTime,
    QuestionSummary,
    QuestionDetails,
    AnswerIds: answerIds,
    Id: questionId,
    Photos: questionPhotos
  } = result;
  let { Answers, searchText, seoPageNumber, channel } = useContext(QAContext);

  const isMobile = channel === 'mobile';

  if (seoPageNumber && seoData) Answers = seoData;

  const [showUserOverlay, setShowUserOverlay] = useState(null);

  const handleUserOverlay = (event, index) => {
    setShowUserOverlay(index);
  };

  const validateMagicApronBadge = (CampaignId) => {
    if (!CampaignId) return false;
    return CampaignId.toLowerCase().indexOf('magicapron') !== -1;
  };

  const filteredAnswers = useMemo(() => {
    if (Answers?.length) {
      return Answers?.filter((answer) => {
        return answerIds.indexOf(answer?.AnswerId) !== -1;
      });
    }

    return [];
  }, [Answers]);

  return (
    <div className="sui-w-full sui-p-0" data-testid="answer-container">
      {filteredAnswers?.length > 0
        && filteredAnswers.map((answer, index) => {
          const { Answer } = answer;
          const {
            Id,
            AnswerText,
            AuthorId,
            BadgesOrder,
            CampaignId,
            LastModificationTime,
            UserNickname,
            TotalPositiveFeedbackCount,
            Photos
          } = Answer;
          const userBadges = [...BadgesOrder];

          if (validateMagicApronBadge(CampaignId)) {
            userBadges.push('magicapron');
          }

          return (
            isMobile ? (
              <Card className="sui-mb-4 sui-w-full" key={Id}>
                <div className="sui-flex sui-flex-col">
                  <CardContent disableGutters disablePadding>
                    <CardTitle
                      disableTypography
                      header={(
                        <Typography
                          data-testid="answer-text"
                          variant="body-base"
                        >
                          {getHighlightedText(Id, AnswerText, searchText)}
                        </Typography>
                      )}
                    />
                    <div className="sui-text-subtle sui-text-base sui-py-3 sui-w-full">
                      <span>By</span>
                      <button
                        className="sui-underline sui-pb-1 sui-px-1"
                        type="button"
                        data-testid="username-link"
                        onClick={() => handleUserOverlay(index)}
                      >
                        {UserNickname}
                      </button>
                      <span>|</span>
                      <span className="sui-pl-1">{formatDate(LastModificationTime)}</span>
                    </div>
                    {userBadges
                    && (
                      <div className="sui-flex sui-flex-col">
                        <UserBadges badges={userBadges} channel={channel} />
                      </div>
                    )}
                  </CardContent>
                  <MediaCarousel photos={Photos} answer={answer} question={QuestionSummary} />
                </div>
                <hr className="sui-pb-2" />
                <CardContent>
                  <Voting totalPositiveFeedbackCount={TotalPositiveFeedbackCount} answerId={Id} />
                  <Overlay
                    onClose={() => setShowUserOverlay(null)}
                    open={showUserOverlay === index}
                    positionedCloseButton
                    closeButton
                    medium
                  >
                    <UserProfile
                      showUserOverlay={showUserOverlay === index}
                      userNickname={UserNickname}
                      authorId={AuthorId}
                    />
                  </Overlay>
                </CardContent>
              </Card>
            )
              : (
                <Card className="sui-mb-4 sui-w-full" key={Id}>
                  <div className="sui-flex md:sui-flex-row">
                    <CardContent disableGutters disablePadding>
                      <CardTitle
                        data-testid="answer-text"
                        disableTypography
                        header={(
                          <Typography
                            variant="body-base"
                          >
                            {getHighlightedText(Id, AnswerText, searchText)}
                          </Typography>
                        )}
                      />
                      <div className="sui-text-subtle sui-text-base sui-py-3 sui-w-full">
                        <span>By</span>
                        <button
                          className="sui-underline sui-pb-1 sui-px-1"
                          type="button"
                          data-testid="username-link"
                          onClick={() => handleUserOverlay(index)}
                        >
                          {UserNickname}
                        </button>
                        <span>|</span>
                        <span className="sui-pl-1">{formatDate(LastModificationTime)}</span>
                      </div>
                      {userBadges
                      && (
                        <div className="sui-flex sui-flex-row">
                          <UserBadges badges={userBadges} channel={channel} />
                        </div>
                      )}
                    </CardContent>
                    {Photos && (
                      <div className="sui-w-1/4">
                        <MediaCarousel photos={Photos} answer={answer} question={QuestionSummary} />
                      </div>
                    )}
                  </div>
                  <hr className="sui-pb-2" />
                  <CardContent>
                    <Voting totalPositiveFeedbackCount={TotalPositiveFeedbackCount} answerId={Id} />
                    <Overlay
                      onClose={() => setShowUserOverlay(null)}
                      open={showUserOverlay === index}
                      positionedCloseButton
                      closeButton
                      medium
                      data-testid="user-profile-overlay"
                    >
                      <UserProfile
                        showUserOverlay={showUserOverlay === index}
                        userNickname={UserNickname}
                        authorId={AuthorId}
                      />
                    </Overlay>
                  </CardContent>
                </Card>
              )
          );
        })}
    </div>
  );
};

AnswerContainer.displayName = 'AnswerContainer';

AnswerContainer.propTypes = {
  result: shape({}),
  seoData: arrayOf(shape({}))
};

AnswerContainer.defaultProps = {
  result: {},
  seoData: null
};
