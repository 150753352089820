import React, { useContext, useState, Suspense } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { CardMedia, CardContent } from '@one-thd/sui-atomic-components';
import { CustomerImageOverlay } from '@thd-olt-component-react/customer-image-overlay';
import { Carousel } from '@one-thd/sui-carousel';
import { triggerAnalytics } from '../../../Analytics';
import QAContext from '../../../context/QAContext';

export const MediaCarousel = ({ photos, question, answer }) => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);
  const { channel } = useContext(QAContext);

  const onOverlayInteraction = () => {
    const payload = {
      origin: 'questions and answers',
      location: 'media',
      value: 'gallery',
      eventType: 'click'
    };
    triggerAnalytics(payload);
  };

  const handleOpen = (Photo) => {
    setCurrentMedia(Photo);
    setOverlayOpen(true);
    onOverlayInteraction();
  };
  const onCloseHandler = () => {
    setOverlayOpen(false);
  };

  if (!photos || photos.length === 0) return null;

  return (
    <>
      <div>
        <CardContent disablePadding="y">
          <Carousel
            type="hero"
            disableShadow
            hidePaginationOnOneOfOne
          >
            {photos.map((Photo) => (
              <button
                type="button"
                onClick={() => {
                  handleOpen(Photo);
                }}
                data-testid="media-carousel-button"
              >
                <CardMedia
                  src={Photo?.Sizes?.normal?.Url}
                  alt="answer_image"
                  aspect="square"
                  width="fit"
                  key={Photo?.Sizes?.normal?.Url}
                />
              </button>
            ))}
          </Carousel>
        </CardContent>
      </div>
      {overlayOpen && currentMedia && (
        <Suspense fallback={(<div />)}>
          <CustomerImageOverlay
            channel={channel}
            photos={photos}
            selectedPhotoID={currentMedia.Id}
            loopPhotos={photos?.length > 1}
            onInteraction={onOverlayInteraction}
            isQuestionsAndAnswerPhotos
            question={question}
            answer={answer.Answer}
            onClose={onCloseHandler}
          />
        </Suspense>
      )}
    </>
  );
};

MediaCarousel.displayName = 'MediaCarousel';

MediaCarousel.propTypes = {
  photos: arrayOf(shape({})),
  question: string,
  answer: arrayOf(shape({}))
};

MediaCarousel.defaultProps = {
  photos: [],
  question: '',
  answer: {}
};
