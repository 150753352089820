import React, { useContext } from 'react';
import { arrayOf, shape, bool } from 'prop-types';
import {
  Accordion, AccordionHeader, Skeleton, SkeletonBlock, AccordionBody
} from '@one-thd/sui-atomic-components';
import { AppContext } from '@thd-nucleus/app-render';
import { QuestionContainer } from '../QAResult/component/QuestionContainer';
import { AnswerContainer } from '../QAResult/component/AnswerContainer';
import QAContext from '../../context/QAContext';
import { useQAResult } from '../QAResult/useQAResult';
import { triggerAnalytics } from '../../Analytics';
import { dataModel } from '../dataModel';
import '../QAResult/QAResult.scss';

const QAResultContent = ({ seoAnswers, seoResults, showQAContent }) => {

  const {
    qaLoading, seoPageNumber, Results
  } = useContext(QAContext);
  const { instance } = useContext(AppContext);
  const qaResult = useQAResult();

  if (!showQAContent) return <></>;

  const thdSeoBotDetected = instance?.thdSEOBotDetection;

  const isSeoPage = !!(seoPageNumber && qaResult);
  const results = seoResults || Results || [];

  if (qaLoading) {
    return (
      <>
        <Skeleton>
          <SkeletonBlock />
        </Skeleton>
        <Skeleton>
          <SkeletonBlock />
        </Skeleton>
        <Skeleton>
          <SkeletonBlock />
        </Skeleton>
        <Skeleton>
          <SkeletonBlock />
        </Skeleton>
      </>
    );
  }

  const analyticsReadQuestion = () => {
    const payload = { component: 'read question' };
    triggerAnalytics(payload);
  };

  return (
    <div>
      <div className="sui-w-full sui-py-2" aria-label="questions">
        {results.length > 0 && results.map((result) => {
          const { Id } = result;
          return (
            <>
              <Accordion
                onChange={analyticsReadQuestion}
                key={Id}
                noBorders
                defaultExpanded={thdSeoBotDetected}
              >
                <AccordionHeader expandIcon={result.AnswerIds <= 0} data-testid="question-header">
                  <QuestionContainer result={result} />
                </AccordionHeader>
                <AccordionBody>
                  <AnswerContainer result={result} seoData={seoAnswers} />
                </AccordionBody>
              </Accordion>
              <hr className="sui-pb-4" />
            </>
          );
        })}
      </div>
    </div>
  );
};

QAResultContent.displayName = 'QAResultContent';

QAResultContent.propTypes = {
  seoAnswers: arrayOf(shape({})),
  seoResults: arrayOf(shape({})),
  showQAContent: bool
};

QAResultContent.defaultProps = {
  seoAnswers: null,
  seoResults: null,
  showQAContent: true
};

QAResultContent.dataModel = dataModel;

export { QAResultContent };
